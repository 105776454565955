import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { update } from "../../../../store/MappedEDPs/mappedEPDSlice";

import { Autocomplete } from "@mui/material";
import { linkUserToManufacturer } from "../../../../store/InviteUser/inviteUserSlice";
import { list } from "../../../../store/Manufactures/manufactureSlice";
import { showMessage } from "../../../../store/Revalu/notificationSlice";

const ManufacturerDetails = (props) => {
  const dispatch = useDispatch();
  const { id, onClose, entry, option = "epd", refreshPage = () => {} } = props;
  const [saving, setSaving] = useState(false);

  const { data: manufacturerDataList, loading } = useSelector(
    ({ manufactures }) => manufactures.slice,
  );

  const handleChangeManufacturerAttribute = (e, manufacturerEntry) => {
    setValue("contactId", manufacturerEntry.manufacturerId);
  };

  useEffect(() => {
    if (manufacturerDataList.size !== 100000) {
      dispatch(list({ pageNo: 0, pageSize: 100000, searchValue: "" }));
    }
  }, []);

  const schema = yup.object().shape({
    field: yup.string(),
    contactId: yup.string(),
  });

  const defaultValues = {
    field: "",
    contactId: entry?.manufacturerId,
  };

  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (values) => {
    const manufactureCurrentId = values?.contactId;
    try {
      setSaving(true);
      let res;

      if (option === "epd") {
        res = await dispatch(
          update({
            fieldName: "manufacturerId",
            dataSourceId: id,
            value: manufactureCurrentId,
          }),
        );
      } else {
        res = await dispatch(linkUserToManufacturer(id, manufactureCurrentId));
        refreshPage();
      }

      if (option !== "epd") {
        dispatch(
          showMessage({ message: "Linked successfully - Refresh needed" }),
        );
        onClose();
      } else {
        if (res) {
          dispatch(
            showMessage({ message: "Linked successfully - Refresh needed" }),
          );
          onClose();
        } else {
          throw new Error("An unknown error occurred");
        }
      }
    } catch (ex) {
      console.error(ex);
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
    } finally {
      setSaving(false);
      onClose();
    }
  };

  return (
    <>
      {loading ? (
        <DialogContent textAlign="center">
          <CircularProgress color="primary" />
        </DialogContent>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Autocomplete
              onChange={handleChangeManufacturerAttribute}
              fullWidth
              // disablePortal
              id="fieldName"
              value={
                manufacturerDataList.content.find(
                  (m) => m.manufacturerId === getValues()?.contactId,
                ) || null
              }
              options={manufacturerDataList.content}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.manufacturerId}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  required
                  {...params}
                  label="Manufacturers"
                  sx={{ width: "100%" }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "10px" }}
              disabled={!isValid}>
              {saving ? "Linking..." : "Link"}
            </Button>
          </DialogActions>
        </form>
      )}
    </>
  );
};

export default ManufacturerDetails;
