import { Box, Button, CircularProgress, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import MUIDataTable from "mui-datatables";
import {
  deleteManufactureSupplier,
  getManufactureSuppliers,
} from "../../../../store/Manufactures/manufactureSlice";
import { showMessage } from "../../../../store/Revalu/notificationSlice";
import SupplierDetails from "../supplierDetails";

const ViewAllSuppliers = (props) => {
  const dispatch = useDispatch();
  const { id } = props;
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const refresh = () => setUpdate((prev) => setUpdate(!prev));

  useEffect(() => {
    const fetchSuppliers = async () => {
      setLoading(true);
      const res = await dispatch(getManufactureSuppliers(id));
      setLoading(false);
      if (res.length) {
        setSuppliers(res);
      } else {
        setLoading(false);
        dispatch(showMessage({ message: "No Suppliers Found" }));
      }
    };
    fetchSuppliers();
  }, [id, dispatch, update]);

  const deleteSupplier = async (values) => {
    const data = {
      manufacturerId: id,
      supplierId: values?.id,
    };
    setLoading(true);
    const res = await dispatch(deleteManufactureSupplier(data));
    setLoading(false);
    if (res) {
      refresh();
      dispatch(showMessage({ message: "Unlink Successfully" }));
    } else {
      setLoading(false);
      dispatch(showMessage({ message: "No Suppliers Found" }));
    }
  };

  const headers = [
    { name: "Name", options: { sort: true } },
    { name: "Email", options: { sort: false } },
    { name: "TelephoneNo", options: { sort: false } },
    { name: "Country", options: { sort: false } },
    { name: "City", options: { sort: false } },
    { name: "Street", options: { sort: false } },
    { name: "Zip Code", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => deleteSupplier(value)}>
              Unlink
            </Button>
          );
        },
      },
    },
  ];

  const getParsedData = () => {
    return suppliers?.map((m) => [
      m.contactName,
      m?.email,
      m?.telephoneNo,
      m?.country,
      m?.city,
      m?.street,
      m?.zipCode,
      {
        id: m?.id,
      },
    ]);
  };

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    search: false,
    pagination: false,
  };

  return (
    <DialogContent>
      <SupplierDetails id={id} refresh={refresh} />
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,0.3)",
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 20px",
            zIndex: 999,
            "& >div": {
              zIndex: 1000,
            },
          }}>
          <CircularProgress />
        </Box>
      )}
      <hr />
      <div>
        <MUIDataTable
          title=""
          columns={headers}
          data={getParsedData()}
          options={{ ...options }}
        />
      </div>
    </DialogContent>
  );
};

export default ViewAllSuppliers;
