import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";

// design
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";

// redux
import {
  addTagInMaterial,
  getTags,
} from "../../../../store/MaterialTags/materialTags";
import { showMessage } from "../../../../store/Revalu/notificationSlice";

const PREFIX = "tagsDetails";

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  formWrapper: `${PREFIX}-formWrapper`,
  label: `${PREFIX}-label`,
  submitButton: `${PREFIX}-submitButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },

  [`& .${classes.formWrapper}`]: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },

  [`& .${classes.label}`]: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },

  [`& .${classes.submitButton}`]: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}));

const TagsDetails = (props) => {
  const { id, onClose, option = "", refreshPage = () => {} } = props;
  const [tags, setTags] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTags = async () => {
      const response = await dispatch(getTags());
      setTagsList(response.content);
    };
    fetchTags();
  }, []);

  const getTagNames = () => {
    const names = tagsList?.map((m) => ({
      label: m.is_llm ? `${m?.name} LLM` : m.name,
      id: m.id,
    }));
    setTags(names);
  };

  useEffect(() => {
    getTagNames();
  }, [tagsList]);

  const schema = yup.object().shape({
    materialTagDataSourceId: yup.string(),
    tagId: yup.string(),
  });

  const defaultValues = {
    materialTagDataSourceId: id,
    tagId: "",
  };

  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      const res = await dispatch(addTagInMaterial(values));
      if (res) {
        dispatch(showMessage({ message: "Linked successfully" }));
        refreshPage();
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
    } finally {
      setSaving(false);
      onClose();
    }
  };

  const handleChangeManufacturerAttribute = (e, name) => {
    setValue("tagId", name.id);
  };

  return (
    <Root className={classes.pageWrapper}>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <div className={classes.formWrapper}>
              <Autocomplete
                onChange={handleChangeManufacturerAttribute}
                fullWidth
                // disablePortal
                id="fieldName"
                options={tags}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    {...params}
                    label="Tags"
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "10px" }}
              disabled={!isValid}
              className={classes.submitButton}>
              {saving ? "Adding" : "Add"}
            </Button>
          </DialogActions>
        </form>
      )}
    </Root>
  );
};

export default TagsDetails;
