import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import {
  updateDocument,
  uploadDocument,
} from "../../../../store/MappedEDPs/mappedEPDSlice";

import { Autocomplete } from "@mui/material";
import { useState } from "react";
import { showMessage } from "../../../../store/Revalu/notificationSlice";
import { getDocumentURI } from "../../../../utils/fileUpload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadDocument = ({
  currentRecord,
  datasource,
  onClose = () => {},
  setReferenceProject = () => {},
  handleReferenceProject = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");

  const id = currentRecord?.id;
  const defaultValues = {
    adminUserName:
      currentRecord?.adminUserName || localStorage.getItem("email_id") || "",
    dataSourceId: currentRecord?.dataSourceId || datasource,
    documentUri: currentRecord?.documentUri || "",
    source: currentRecord?.source || "Manufacturer",
    type: currentRecord?.type || "",
    uploadedFileName: datasource + "_" || "",
  };

  const addNewSchema = yup.object().shape({
    adminUserName: yup.string(),
    dataSourceId: yup.string(),
    documentUri: yup.string().required("Required"),
    source: yup.string(),
    type: yup.string(),
    selectedTypeOption: yup.string(),
    uploadedFileName: yup.string(),
  });

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(addNewSchema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const TYPES = [
    "Declaration of Performance",
    "Product Data Sheet",
    "Safety Data Sheet",
    "European Technical Assessment",
    "Material Safety Datasheet",
    "BREEAM Helpsheet",
    "Construction Details",
    "Panel project Preparation",
    "Building Elements",
    "Assembly Details",
    "Screw Connections",
    "Electrical Installations",
    "Wufi Report",
    "Warranty",
    "Transport Cost Guide",
    "Transport & Storage Guide",
    "Order Guide",
    "EUrofin Certificate",
    "BBA Certificate",
    "PH Certified Details",
    "FSC Certification",
    "Kiwa Verification Certification",
    "PH Certification Report",
    "Passivhaus Certificate",
    "Cradle to Cradle Certificate",
    "NBS Specification",
    "Building Physics Guide",
    "Specification Guide",
    "Assembly Guide",
    "Installation Guide",
    "Product Brochure",
    "Sample Structural Calculations",
    "Earthquake Results",
    "Structural Design",
    "Reaction to Fire & Fire Resistance",
    "Fire Protection",
    "Classification of Fire Resistance",
    "Reference Project",
    "Life Cycle Assessment",
    "Certificate",
    "Other document",
    "Technical Data Sheet",
    "Toxicity Declaration",
    "Product Catalogue",
    "Care and Maintenance Sheet",
    "Climate declaration",
  ];

  TYPES.sort();

  // Add the EPD as the first element
  TYPES.unshift("Environmental Product Declaration");

  const onResponse = (res) => {
    if (Boolean(res)) {
      dispatch(
        showMessage({
          message: `The document has been ${id ? "updated" : "uploaded"}`,
        }),
      );
      reset();
      onClose();
    } else if (Number(status) === 400) {
      if (
        data &&
        data["database exception"]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf("duplicate") > -1
      ) {
        dispatch(showMessage({ message: "already exists", variant: "error" }));
      }
    }
  };

  const onSubmit = async (model) => {
    setLoading(true);
    if (Boolean(id)) {
      const res = await dispatch(updateDocument(id, model));
      onResponse(res);
    } else {
      const res = await dispatch(uploadDocument(model));
      onResponse(res);
    }
    setLoading(false);
  };

  const handleUpload = (e, field) => {
    if (e.target.files.length) {
      setLoading(true);
      getDocumentURI(e.target.files[0], false, selectedFileName)
        .then((res) => {
          setValue(field, res.data);
          setValue("uploadedFileName", e.target.files[0].name);
          // setSelectedFileName(e.target.files[0].name);
        })
        .catch((err) => {
          alert(err?.message || "Error uploading file");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleTypes = (e, newValue) => {
    setValue("type", newValue);
    handleTypeSelection(newValue);
    if (newValue === "Reference Project") {
      setReferenceProject(true);
      handleReferenceProject(datasource);
      onClose();
    } else {
      setReferenceProject(false);
      handleReferenceProject(null);
    }
  };

  const handleTypeSelection = (typeSelection) => {
    let typeName = typeSelection;
    if (typeName === "Environmental Product Declaration") {
      typeName = "EPD";
    }
    setSelectedFileName(`${datasource}_${typeName}`);
  };

  const handlefileName = (e) => {
    setSelectedFileName(e.target.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12}>
              <div>
                <label>Type</label>
                <Autocomplete
                  onChange={handleTypes}
                  fullWidth
                  id="fieldName"
                  options={TYPES}
                  renderInput={(params) => (
                    <TextField
                      id="text-input-upload-document"
                      variant="outlined"
                      fullWidth
                      required
                      {...params}
                      label="Document Type"
                      sx={{ mt: 1 }}
                    />
                  )}
                />
                <Grid item xs={12} md={12}>
                  <Controller
                    name="type"
                    control={control}
                    className="textfield"
                    render={({ field }) => (
                      <TextField
                        id="text-input-upload-document"
                        {...field}
                        label={"Selected Type"}
                        error={!!errors.type}
                        helperText={errors?.type?.message}
                        variant="outlined"
                        type="text"
                        placeholder="Type"
                        sx={{ width: "100%", mt: 2 }}
                      />
                    )}
                  />

                  <TextField
                    required
                    label={"Filename"}
                    value={selectedFileName}
                    onChange={handlefileName}
                    sx={{ width: "100%", my: 2 }}
                  />
                </Grid>

                <label>Document</label>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                  }}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    disabled={
                      loading ||
                      selectedFileName === "" ||
                      getValues("documentUri")
                    }
                    sx={{ mb: 2 }}
                    startIcon={<CloudUploadIcon />}>
                    {getValues("documentUri") ? "PDF uploaded" : "Upload PDF"}
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(e) => handleUpload(e, "documentUri")}
                      multiple
                    />
                  </Button>
                  {loading && <CircularProgress />}
                  <Grid item xs={3} md={3}>
                    <div>
                      <label id="file-input-label" htmlFor="file-input">
                        Choose File
                      </label>
                    </div>
                  </Grid>
                </div>
                {getValues("documentUri") && "The Document is uploaded"}
              </div>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <Controller
                name="source"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="text-input-upload-document"
                    {...field}
                    error={!!errors.source}
                    label={"Source"}
                    helperText={errors?.source?.message}
                    variant="outlined"
                    placeholder="Source"
                    inputProps={{ maxLength: 240, mt: 2 }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!Boolean(getValues("documentUri")) || loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </DialogActions>
      </form>
    </div>
  );
};

export default UploadDocument;
