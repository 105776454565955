import { BusinessCenter, InsertDriveFile } from "@mui/icons-material";
import OrdersIcon from "@mui/icons-material/AddToHomeScreen";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BackupTableIcon from "@mui/icons-material/Backup";
import CampaignIcon from "@mui/icons-material/Campaign";
import ContactMail from "@mui/icons-material/ContactMail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import UserIcon from "@mui/icons-material/PersonOutline";
import React from "react";
import { RouteLinks } from "../Constants/routes";
import FactoryIcon from "@mui/icons-material/Factory";
import StoreIcon from "@mui/icons-material/Store";
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';

const NavBarActions = [
  {
    label: "Dashboard",
    icon: <DashboardIcon />,
    url: RouteLinks.homePage,
  },
  {
    label: "User Management",
    icon: <UserIcon />,
    // url: RouteLinks.user.buyers,
    subMenu: [
      {
        label: "Admin Users",
        icon: <AdminPanelSettingsIcon />,
        isSubMenu: true,
        url: RouteLinks.user.adminUsers,
        className: "submenu_one",
      },
      {
        label: "Platform Users",
        icon: <UserIcon />,
        isSubMenu: true,
        url: RouteLinks.user.platformUsers,
        className: "submenu_one",
      },
      // {
      //   label: "Users Activity",
      //   icon: <UserIcon />,
      //   isSubMenu: true,
      //   // url: RouteLinks.user.sellers,
      //   className: "submenu_one",
      // },
      // {
      //   label: "Invite Users",
      //   icon: <UserIcon />,
      //   isSubMenu: true,
      //   url: RouteLinks.user.inviteUsers,
      //   className: "submenu_one",
      // },
      // {
      //   label: "Admin Users",
      //   icon: <UserIcon />,
      //   isSubMenu: true,
      //   url: RouteLinks.user.buyers,
      // },
    ],
  },
  {
    label: "EPD Management",
    icon: <OrdersIcon />,
    // url: RouteLinks.user.buyers,
    subMenu: [
      {
        label: "ECO PORTAL EPDs",
        className: "submenu_one",
        icon: <InsertDriveFile />,
        isSubMenu: false,
        // url: RouteLinks.lca.originalEDPs,
        url: RouteLinks.lca.ecoPortalEDPs,
        // subMenu: [
        //   {
        //     label: "ECO PORTAL EPDs",
        //     icon: <OrdersIcon />,
        //     isSubMenu: true,
        //     url: RouteLinks.lca.ecoPortalEDPs,
        //     className: "submenu_two",
        //   },
        //   {
        //     label: "OEKOBAUDAT EPDs",
        //     icon: <OrdersIcon />,
        //     isSubMenu: true,
        //     url: RouteLinks.lca.oekobaudatEPDs,
        //     className: "submenu_two",
        //   },
        // ],
      },
      {
        label: "Mapped EPDs",
        icon: <OrdersIcon />,
        isSubMenu: true,
        url: RouteLinks.lca.mappedEPDs,
        className: "submenu_one",
      },
      // {
      //   label: "Material Tags",
      //   icon: <LocalOfferIcon />,
      //   isSubMenu: true,
      //   url: RouteLinks.lca.tags,
      //   className: "submenu_one",
      // },
    ],
  },
  {
    label: "Manufacturer",
    icon: <BusinessCenter />,
    url: RouteLinks.manufacturer.all,
    subMenu: [
      {
        label: "Manufacturers",
        icon: <FactoryIcon />,
        isSubMenu: true,
        url: RouteLinks.manufacturer.all,
        className: "submenu_one",
      },
      {
        label: "Contacts for Manufacturers",
        icon: <ContactMail />,
        isSubMenu: true,
        url: RouteLinks.manufacturer.contacts,
        className: "submenu_one",
      },
      {
        label: "Suppliers for Manufacturers",
        icon: <StoreIcon />,
        isSubMenu: true,
        url: RouteLinks.manufacturer.supplier,
        className: "submenu_one",
      },
    ],
  },
  // {
  //   label: "Attribute Management",
  //   icon: <InsertChartIcon />,
  //   // url: RouteLinks.user.buyers,
  //   subMenu: [
  //     {
  //       label: "Source",
  //       icon: <InsertChartIcon />,
  //       isSubMenu: true,
  //       url: RouteLinks.attribute.source,
  //       className: "submenu_one",
  //     },
  //     {
  //       label: "Semantic Group",
  //       icon: <InsertChartIcon />,
  //       isSubMenu: true,
  //       url: RouteLinks.attribute.semanticGroup,
  //       className: "submenu_one",
  //     },
  //     {
  //       label: "Objects",
  //       icon: <InsertChartIcon />,
  //       isSubMenu: true,
  //       url: RouteLinks.objectTypes.all,
  //       className: "submenu_one",
  //     },
  //     // {
  //     //   label: "Metadata",
  //     //   icon: <InsertChartIcon />,
  //     //   isSubMenu: true,
  //     //   url: RouteLinks.attribute.metadata,
  //     // },
  //     {
  //       label: "Attribute",
  //       icon: <InsertChartIcon />,
  //       isSubMenu: true,
  //       url: RouteLinks.attribute.attribute,
  //       className: "submenu_one",
  //     },
  //   ],
  // },
  // {
  //   label: "Category Hierarchy",
  //   icon: <CategoryIcon />,
  //   // url: RouteLinks.user.buyers,
  //   subMenu: [
  //     {
  //       label: "Elements",
  //       icon: <CategoryIcon />,
  //       isSubMenu: true,
  //       url: RouteLinks.category_hierarchy.elements,
  //       className: "submenu_one",
  //     },
  //     {
  //       label: "Level 1",
  //       icon: <CategoryIcon />,
  //       isSubMenu: true,
  //       url: RouteLinks.category_hierarchy.level1,
  //       className: "submenu_one",
  //     },
  //     {
  //       label: "Level 2",
  //       icon: <CategoryIcon />,
  //       isSubMenu: true,
  //       url: RouteLinks.category_hierarchy.level2,
  //       className: "submenu_one",
  //     },
  //     {
  //       label: "Relationship",
  //       icon: <CategoryIcon />,
  //       isSubMenu: true,
  //       url: RouteLinks.category_hierarchy.relationship,
  //       className: "submenu_one",
  //     },
  //   ],
  // },
  {
    label: "Custom Material",
    icon: <BackupTableIcon />,
    url: RouteLinks.user.customMaterial,
  },
  {
    label: "Product News",
    icon: <CampaignIcon />,
    url: RouteLinks.productNews,
  },
  {
    label: "Access Management",
    icon: <SettingsAccessibilityIcon />,
    url: RouteLinks.accessMgmt,
  },
  // {
  //   label: "Quality Management",
  //   icon: <LaptopIcon />,
  //   // url: "user/dashboard",
  // },
  // {
  //   label: "Visualization",
  //   icon: <LaptopIcon />,
  //   url: "user/dashboard",
  // },
  // {
  //   label: "Analysis",
  //   icon: <SearchIcon />,
  //   url: "user/dashboard",
  // },
];

export default NavBarActions;
