import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import * as React from "react";

import { useDispatch } from "react-redux";
import AlertDialog from "../../../common/AlertDialog/AlertDialog";
import { setConfirmation } from "../../../store/AlertDialog/alertDialogSlice";

const ITEM_HEIGHT = 48;

function ActionButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const open = Boolean(anchorEl);
  const { rowData, onToggle = () => {} } = props;
  const { id, isAllowed, name, url } = rowData;

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleToggle = () => {
    setAlert(true);
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setAlert(false);
  };

  const handleConfirm = () => {
    onToggle(id, isAllowed);
    setAlert(false);
  };

  if (loading) {
    return <CircularProgress size={20} color="primary" />;
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          marginTop: 10,
          maxHeight: ITEM_HEIGHT * 8.0,
          width: "20ch",
        }}>
        <MenuItem onClick={handleToggle}>Toggle</MenuItem>
      </Menu>

      {alert ? (
        <AlertDialog
          title="Toggle Permission"
          message={`Are you sure want to change the permission for ${name}?`}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      ) : (
        ""
      )}
    </div>
  );
}
export default ActionButtons;
