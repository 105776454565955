export const RouteLinks = {
  homePage: "/",
  login: "/login",
  register: "/register",
  welcome: "/welcome",
  productNews: "/user/productNews",
  accessMgmt: "/user/accessMgmt",
  manufacturer: {
    all: "/user/manufacturer/view-all",
    contacts: "/user/manufacturer/contacts/view-all",
    supplier: "/user/manufacturer/supplier/view-all",
  },
  objectTypes: {
    all: "/user/view-all",
  },
  lca: {
    originalEDPs: "/user/lca/original-edps",
    mappedEPDs: "/user/lca/mapped-epds",
    ecoPortalEDPs: "/user/lca/ecoportal-edps",
    oekobaudatEPDs: "/user/lca/oekobaudat-edps",
    tags: "/user/lca/materialTags",
  },
  user: {
    default: "/user/*",
    dashboard: "/user/dashboard",
    support: "/user/support",
    orders: "/user/all-orders",
    buyers: "/user/all-buyers",
    sellers: "/user/all-seller",
    adminUsers: "/user/all-admin-users",
    glossary: "/user/glossary",
    customMaterial: "/user/customMaterial",
    inviteUsers: "/user/invite-user",
    platformUsers: "/user/platform-user",
  },
  attribute: {
    source: "/user/source",
    semanticGroup: "/user/semanticgroup",
    attribute: "/user/attribute",
    metadata: "/user/metadata",
  },
  category_hierarchy: {
    elements: "/user/elements",
    level1: "/user/level1",
    level2: "/user/level2",
    relationship: "/user/relationship",
  },
};
