import axios from "axios";
import { BASE_HOST_ADMIN_USERS } from "../../config/config";
/* eslint-disable camelcase */
import axiosRequest from "../axiosReq";
class InvitedUserService {
  getAllInvitedUsers = (data = {}) => {
    const req = axios.get(BASE_HOST_ADMIN_USERS + "/users/invite/get", data);
    return axiosRequest(req);
  };

  resendPassCode = (data = {}) => {
    const userEmailRequest = data;
    const req = axios.post(
      BASE_HOST_ADMIN_USERS + "/users/resendPasscode",
      userEmailRequest,
    );
    return axiosRequest(req);
  };

  unlockUser = (id) => {
    const req = axios.put(
      `${BASE_HOST_ADMIN_USERS}/users/setActiveStatusByUserId?id=${id}&&isLocked=false`,
    );
    return axiosRequest(req);
  };

  linkUserToManufacturer = (id, manufacturerId) => {
    const req = axios.put(
      `${BASE_HOST_ADMIN_USERS}/users/linkUserToManufacturer?id=${id}&manufacturerId=${manufacturerId}`,
    );
    return axiosRequest(req);
  };

  unlinkUserToManufacturer = (id, manufacturerId) => {
    const req = axios.put(
      `${BASE_HOST_ADMIN_USERS}/users/unlinkUserToManufacturer?id=${id}`,
    );
    return axiosRequest(req);
  };

  AddInvitedUser = (data = {}) => {
    const { inviteUserAccountTypes, referenceId } = data[0];

    const req = axios.post(
      `${BASE_HOST_ADMIN_USERS}/users/invite/add?inviteUserAccountTypes=${inviteUserAccountTypes}&referenceId=${referenceId}`,
      data,
    );
    return axiosRequest(req);
  };

  resendEmailUser = (data = {}) => {
    const req = axios.post(
      BASE_HOST_ADMIN_USERS + "/users/invite/resend",
      data,
    );
    return axiosRequest(req);
  };

  cancelInviteUser = (data = {}) => {
    const req = axios.delete(BASE_HOST_ADMIN_USERS + "/users/invite/delete", {
      data: data,
    });
    return axiosRequest(req);
  };
}

const instance = new InvitedUserService();

export default instance;
