import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { captureEPDLLM } from "../../../../store/MappedEDPs/mappedEPDSlice";
import { showMessage } from "../../../../store/Revalu/notificationSlice";

const chatGPTExtractionPrompt = `
You are a helpful data collector, who specializes in EPDs of building materials.
The user provides you a JSON with attributes and information on these attributes, and a product name.
Your task is to fill find the values for each attribute for the specified product, given the information of the EPD.
Sometimes, there are multiple products in an EPD. Specifically extract the information for the provided product name.
Translate the provided EPD of a building material into English, then analyze it thoroughly.
Carefully extract the information you can find in the EPD and fill the values in the JSON.
  - If a number in front of scientific notation contains a comma, replace it with a period.
  - Do not convert numbers from scientific notations. Keep the scientific notation as-is.
  - Some tables may have merged cells, ensure the values match the right stages.
  - If an attribute's value can't be found in the EPD, leave it empty.
  - The output structure must be a valid JSON object '{<attribute>: <value>, <attribute2>: <value2>, ...}'.

[
    {"name": "GWP-fossil A1-A3", "info": ""},
    {"name": "GWP-fossil A4", "info": ""},
    {"name": "GWP-fossil A5", "info": ""},
    {"name": "GWP-fossil B1", "info": ""},
    {"name": "GWP-fossil B2", "info": ""},
    {"name": "GWP-fossil B3", "info": ""},
    {"name": "GWP-fossil B4", "info": ""},
    {"name": "GWP-fossil B5", "info": ""},
    {"name": "GWP-fossil B6", "info": ""},
    {"name": "GWP-fossil B7", "info": ""},
    {"name": "GWP-fossil C1", "info": ""},
    {"name": "GWP-fossil C2", "info": ""},
    {"name": "GWP-fossil C3", "info": ""},
    {"name": "GWP-fossil C4", "info": ""},
    {"name": "GWP-fossil D", "info": ""},
    {"name": "GWP-biogenic A1-A3", "info": ""},
    {"name": "GWP-biogenic A4", "info": ""},
    {"name": "GWP-biogenic A5", "info": ""},
    {"name": "GWP-biogenic B1", "info": ""},
    {"name": "GWP-biogenic B2", "info": ""},
    {"name": "GWP-biogenic B3", "info": ""},
    {"name": "GWP-biogenic B4", "info": ""},
    {"name": "GWP-biogenic B5", "info": ""},
    {"name": "GWP-biogenic B6", "info": ""},
    {"name": "GWP-biogenic B7", "info": ""},
    {"name": "GWP-biogenic C1", "info": ""},
    {"name": "GWP-biogenic C2", "info": ""},
    {"name": "GWP-biogenic C3", "info": ""},
    {"name": "GWP-biogenic C4", "info": ""},
    {"name": "GWP-biogenic D", "info": ""},
    {"name": "GWP-luluc A1-A3", "info": ""},
    {"name": "GWP-luluc A4", "info": ""},
    {"name": "GWP-luluc A5", "info": ""},
    {"name": "GWP-luluc B1", "info": ""},
    {"name": "GWP-luluc B2", "info": ""},
    {"name": "GWP-luluc B3", "info": ""},
    {"name": "GWP-luluc B4", "info": ""},
    {"name": "GWP-luluc B5", "info": ""},
    {"name": "GWP-luluc B6", "info": ""},
    {"name": "GWP-luluc B7", "info": ""},
    {"name": "GWP-luluc C1", "info": ""},
    {"name": "GWP-luluc C2", "info": ""},
    {"name": "GWP-luluc C3", "info": ""},
    {"name": "GWP-luluc C4", "info": ""},
    {"name": "GWP-luluc D", "info": ""},
    {"name": "GWP A1-A3", "info": "The total GWP."},
    {"name": "GWP A4", "info": "The total GWP."},
    {"name": "GWP A5", "info": "The total GWP."},
    {"name": "GWP B1", "info": "The total GWP."},
    {"name": "GWP B2", "info": "The total GWP."},
    {"name": "GWP B3", "info": "The total GWP."},
    {"name": "GWP B4", "info": "The total GWP."},
    {"name": "GWP B5", "info": "The total GWP."},
    {"name": "GWP B6", "info": "The total GWP."},
    {"name": "GWP B7", "info": "The total GWP."},
    {"name": "GWP C1", "info": "The total GWP."},
    {"name": "GWP C2", "info": "The total GWP."},
    {"name": "GWP C3", "info": "The total GWP."},
    {"name": "GWP C4", "info": "The total GWP."},
    {"name": "GWP D", "info": "The total GWP."},
    {"name": "FW A1-A3", "info": "Amount of fresh water used."},
    {"name": "FW A4", "info": "Amount of fresh water used."},
    {"name": "FW A5", "info": "Amount of fresh water used."},
    {"name": "FW B1", "info": "Amount of fresh water used."},
    {"name": "FW B2", "info": "Amount of fresh water used."},
    {"name": "FW B3", "info": "Amount of fresh water used."},
    {"name": "FW B4", "info": "Amount of fresh water used."},
    {"name": "FW B5", "info": "Amount of fresh water used."},
    {"name": "FW B6", "info": "Amount of fresh water used."},
    {"name": "FW B7", "info": "Amount of fresh water used."},
    {"name": "FW C1", "info": "Amount of fresh water used."},
    {"name": "FW C2", "info": "Amount of fresh water used."},
    {"name": "FW C3", "info": "Amount of fresh water used."},
    {"name": "FW C4", "info": "Amount of fresh water used."},
    {"name": "FW D", "info": "Amount of fresh water used."},
    {"name": "PERT A1-A3", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT A4", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT A5", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT B1", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT B2", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT B3", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT B4", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT B5", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT B6", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT B7", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT C1", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT C2", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT C3", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT C4", "info": "Primary energy content of all renewable resources."},
    {"name": "PERT D", "info": "Primary energy content of all renewable resources."},
    {"name": "PENRT A1-A3", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT A4", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT A5", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT B1", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT B2", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT B3", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT B4", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT B5", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT B6", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT B7", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT C1", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT C2", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT C3", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT C4", "info": "Primary energy content of all non-renewable resources."},
    {"name": "PENRT D", "info": "Primary energy content of all non-renewable resources."},
]
`;

const StartLLMEPDCapture = (props) => {
  const dispatch = useDispatch();
  const { id, onClose, entry } = props;
  const [saving, setSaving] = useState(false);
  const [productName, setProductName] = useState(entry?.Name_Product);
  const [jsonPayload, setJsonPayload] = useState("");

  const [isValid, setIsValid] = useState(true);

  const validateJson = (inputValue) => {
    try {
      JSON.parse(inputValue);
      setIsValid(true);
    } catch (error) {
      if (inputValue === "") {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setJsonPayload(inputValue);
    validateJson(inputValue);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    const data = {
      product_name: productName,
      lca_stages: jsonPayload !== "" ? JSON.parse(jsonPayload) : {},
    };
    const res = await dispatch(captureEPDLLM(data, id));
    setSaving(false);
    if (res?.status === 500) {
      dispatch(showMessage({ message: res?.data?.message, variant: "error" }));
    } else if (res?.status === 202) {
      dispatch(
        showMessage({
          message: res?.data?.message,
        }),
      );
      onClose();
    } else {
      dispatch(
        showMessage({
          message: "Request timed out please check capture sheet",
          variant: "error",
        }),
      );
      onClose();
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="Product Name"
            variant="outlined"
            required
            sx={{ width: "100%", mb: 3 }}
            value={productName}
            onChange={(e) => {
              setProductName(e.target.value);
            }}
          />
          <br />
          <Button
            variant="text"
            onClick={() => {
              navigator.clipboard.writeText(chatGPTExtractionPrompt);
            }}>
            Copy Prompt for LCA Stage Extraction
          </Button>
          <TextField
            id="outlined-multiline-static"
            label="JSON LCA Stage input"
            sx={{ width: "100%" }}
            multiline
            rows={10}
            value={jsonPayload}
            onChange={handleInputChange}
            placeholder="{...}"
            error={isValid === false}
            helperText={isValid === false ? "Invalid JSON format" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: "10px" }}
            disabled={productName === "" || !isValid}>
            {saving ? "Please wait..." : "Start LLM Extraction"}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default StartLLMEPDCapture;
