import { Box, CircularProgress } from "@mui/material";
import MUIDataTable from "mui-datatables";
import TableActionButton from "../Common/tableActionButton";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountTypes,
  getAppActionData,
  updateEndpointPermission,
  syncAllEndpoints,
  resetData,
} from "../../../store/AccessMgmt/accessMgmtSlice";
import ActionButtons from "./actionButtons";

const AccessMgmt = () => {
  const [accountType, setAccountType] = useState("");
  const [appName, setAppName] = useState("revalu-epd-service");
  const dispatch = useDispatch();
  const accountTypedata = useSelector(
    (state) => state.accessMgmt.slice.accountTypedata,
  );

  const loading = useSelector((state) => state.accessMgmt.slice.loading);

  const appActiondata = useSelector(
    (state) => state.accessMgmt.slice.appActiondata,
  );

  const handleFetchData = () => {
    dispatch(getAppActionData(accountType, appName));
  };

  const handleSyncEndpoints = () => {
    dispatch(syncAllEndpoints());
  };

  const toggleData = (id, isAllowed) => {
    dispatch(updateEndpointPermission(id, accountType, !isAllowed, appName));
  };

  const options = {
    pagination: false, // Disables pagination
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    serverSide: true,
    onColumnSortChange: (col, direction) => handleSortBy(col, direction),
  };

  const headers = [
    {
      name: "ID",
      options: {
        sort: false,
      },
    },
    {
      name: "Name",
      options: {
        sort: false,
      },
    },
    {
      name: "URL",
      options: {
        sort: false,
      },
    },
    {
      name: "Is Allowed",
      options: {
        sort: false,
      },
    },
    {
      name: "Actions",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return <ActionButtons rowData={value} onToggle={toggleData} />;
        },
      },
    },
  ];

  const getParsedData = () => {
    return appActiondata?.content?.map((m) => {
      return [m?.id, m.name, m.url, m.isAllowed ? "True" : "False", m];
    });
  };

  useEffect(function () {
    dispatch(resetData());
    fetchAccountTypes();
  }, []);

  async function fetchAccountTypes() {
    dispatch(getAccountTypes());
  }

  return (
    <div>
      <div>
        <br></br>
      </div>
      <div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "20px",
            mb: 2,
          }}>
          <Box
            display="inline-flex"
            justifyContent="flex-end"
            gap="10px"
            flexWrap="wrap">
            <label className="font-medium">Account Type</label>
            &nbsp;&nbsp;
            <select onChange={(e) => setAccountType(e.target.value)}>
              <option> -- Select Account type -- </option>
              {accountTypedata.content.map((d) => (
                <option key={d.id} value={d.id}>
                  {" "}
                  {d.name}{" "}
                </option>
              ))}
            </select>
            &nbsp;&nbsp;
            <label className="font-medium">Application Name</label>
            &nbsp;&nbsp;
            <select onChange={(e) => setAppName(e.target.value)}>
              <option value="revalu-epd-service"> EPD Service </option>
              <option value="revalu-user-service"> User Service </option>
            </select>
            <TableActionButton
              onClick={() => {
                handleFetchData();
              }}
              title="Fetch Data"
            />
            <TableActionButton
              onClick={() => {
                handleSyncEndpoints();
              }}
              title="Sync Endpoints"
            />
          </Box>
        </Box>
      </div>

      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,0.3)",
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 20px",
            zIndex: 999,
            "& >div": {
              zIndex: 1000,
            },
          }}>
          <CircularProgress />
        </Box>
      )}

      <div>
        <div>
          <MUIDataTable
            title="Access Management"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: appActiondata?.content?.count }}
            onPageChange={() => {}}
          />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default AccessMgmt;
