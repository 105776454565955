import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import useAttributes from "../../../../hooks/useAttributes";
import {
  deleteKeyword,
  getById,
  update,
} from "../../../../store/MappedEDPs/mappedEPDSlice";
import { showMessage } from "../../../../store/Revalu/notificationSlice";
import { handleUploadFile } from "../../../../utils/fileUpload";
import AttributeDetails from "./attributeDetails";
import { uploadFieldNames } from "./constants";
const defaultValues = {
  fieldName: "",
  value: "",
};

const EditEPD = (props) => {
  const fileRef = useRef();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const { id } = props;
  const [data, setData] = useState({});
  const { attributes, attributesList } = useAttributes();
  const [name, setName] = useState("");

  const fetchData = () => {
    if (id) {
      (async () => {
        try {
          const res = await dispatch(getById(id));
          if (res && Boolean(res.length)) {
            setData(res[0]);
          }
        } catch (ex) {
          dispatch(
            showMessage({ message: ex.message || ex, variant: "error" }),
          );
        } finally {
          setLoading(false);
        }
      })();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getBoolTypeFromName = (name) =>
    name && (attributes[name] === "Float" || attributes[name] === "Integer")
      ? "number"
      : "text";
  const getTypeFromName = (name) => attributes[name] || "String";
  const getValueFromName = (name) => data[name];

  const schema = yup.object().shape({
    value: yup.lazy((val) =>
      attributeIsNumberType(val)
        ? yup.number().required("Required")
        : yup.string().required("Required"),
    ),
    fieldName: yup.string().required("Required"),
    manufacturefieldName: yup.string(),
  });

  const { control, formState, handleSubmit, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const attributeIsNumberType = (attr) => {
    return getBoolTypeFromName(attr) === "number";
  };
  const { isValid, errors } = formState;

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      const value = setValueWithType(values.value) || "";
      const res = await dispatch(
        update({
          ...values,
          dataSourceId: id,
          value: value,
        }),
      );
      if (res && Boolean(res.modifiedCount)) {
        dispatch(showMessage({ message: "Saved successfully" }));
        fetchData();
      } else {
        throw new Error("An unknown error occurred");
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
    } finally {
      setSaving(false);
    }
  };
  const handleChangeAttribute = (e, name) => {
    setValue("value", "");
    const itemValue = getValueFromName(name);
    setValue("fieldName", name);
    if (name && itemValue) {
      setValue("value", itemValue || "");
    } else {
      setValue("value", "");
    }
    setName(name);
  };

  const convertType = (val, type) => {
    if (type === "Float") {
      return parseFloat(val) || val;
    } else if (type === "Integer") {
      return parseInt(val) || val;
    } else {
      return val || "";
    }
  };

  const setValueWithType = (val) => {
    const formField = getValues();
    const name = formField.fieldName;
    const dataType = getTypeFromName(name);
    return convertType(val, dataType);
  };
  const isUploadField = uploadFieldNames.includes(name);

  const openFiles = () => {
    fileRef.current.click();
  };
  const handleUpload = (e) => {
    if (e.target.files.length) {
      setUploading(true);
      handleUploadFile(e, true)
        .then((res) => {
          // await trigger("value")
          const finalValue = res.data.replace(/&/g, "%26");
          setValue("value", finalValue);
        })
        .catch((err) => {
          setValue("value", "");
          alert(err?.message || "Error uploading file");
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  const handleDelete = async () => {
    const data = {
      dataSourceId: id,
      fieldName: name,
    };
    try {
      setDeleting(true);
      const res = await dispatch(deleteKeyword(data));
      if (res && Boolean(res.modifiedCount)) {
        dispatch(showMessage({ message: "Deleted successfully" }));
        fetchData();
      } else {
        throw new Error("An unknown error occurred");
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }));
    } finally {
      setDeleting(false);
    }
  };

  return (
    <DialogContent>
      {loading ? (
        <Box textAlign="center" sx={{ py: 20, px: 55 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Box mb={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={7}>
                  <Autocomplete
                    onChange={handleChangeAttribute}
                    fullWidth
                    // disablePortal
                    id="fieldName"
                    options={attributesList}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        {...params}
                        label="Attribute"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={7}>
                  <Controller
                    name="value"
                    control={control}
                    className="textfield"
                    render={({ field }) => {
                      return (
                        <TextField
                          disabled={isUploadField}
                          {...field}
                          id="edit-input"
                          inputProps={
                            attributeIsNumberType(name)
                              ? {
                                  type: "number",
                                }
                              : {}
                          }
                          onKeyDown={handleEnter}
                          error={!!errors.value}
                          helperText={errors?.value?.message}
                          variant="outlined"
                          fullWidth
                          required
                          label="Value"
                          // value={getValues().value}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid
                  sx={{
                    display: "flex",
                    gap: "8px",
                    "& input": {
                      display: "none",
                    },
                  }}
                  item
                  lg={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!name}
                    type="submit">
                    {saving ? "Please wait..." : "Save"}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!name}
                    onClick={handleDelete}>
                    {deleting ? "Please wait..." : "Delete"}
                  </Button>
                  {isUploadField ? (
                    <>
                      {uploading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          // disabled={uploading}
                          onClick={openFiles}
                          variant="contained"
                          color="primary">
                          Upload
                        </Button>
                      )}
                      <input
                        type="file"
                        id="file"
                        ref={fileRef}
                        onChange={handleUpload}></input>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </Box>
          <AttributeDetails data={data} />
        </>
      )}
    </DialogContent>
  );
};

export default EditEPD;
