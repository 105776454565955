import { createSlice } from "@reduxjs/toolkit";
import { accessMgmtService } from "../../services/accessMgmtService";

export const getAccountTypes = () => async (dispatch) => {
  dispatch(setLoading(true));
  return accessMgmtService
    .getAccountTypes()
    .then((res) => {
      dispatch(setAccountTypes(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const getAppActionData =
  (accountTypeId, appName) => async (dispatch) => {
    dispatch(setLoading(true));

    return accessMgmtService
      .getAppActionData(accountTypeId, appName)
      .then((res) => {
        dispatch(setAppActiondata(res));
      })
      .catch((errors) => {})
      .finally(() => {
        dispatch(setLoading(false));
        return true;
      });
  };

export const updateEndpointPermission =
  (endpointIds, accountTypeId, isAllowed, appName) => async (dispatch) => {
    dispatch(setLoading(true));

    return accessMgmtService
      .updateEndpointPermission(endpointIds, accountTypeId, isAllowed)
      .then((res) => {})
      .catch((errors) => {})
      .finally(() => {
        // dispatch(setLoading(false));
        dispatch(getAppActionData(accountTypeId, appName));
        return true;
      });
  };

export const syncAllEndpoints = () => async (dispatch) => {
  dispatch(setLoading(true));
  return accessMgmtService
    .syncAllEndpoints()
    .then((res) => {})
    .catch((errors) => {})
    .finally(() => {
      // dispatch(setLoading(false));
      const res = { data: [] };
      dispatch(setLoading(false));
      dispatch(resetData());
      return true;
    });
};

const initialState = {
  accountTypedata: {
    content: [],
  },
  appActiondata: {
    content: [],
  },
  loading: false,
};

const accessMgmtSlice = createSlice({
  name: "accessMgmt",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAccountTypes(state, action) {
      state.accountTypedata.content = action.payload.data;
    },
    setAppActiondata(state, action) {
      state.appActiondata.content = action.payload.data;
    },
    resetData(state, action) {
      state.appActiondata.content = [];
    },
  },
});

export const { setLoading, setAccountTypes, setAppActiondata, resetData } =
  accessMgmtSlice.actions;

export default accessMgmtSlice.reducer;
