import axios from "axios";
import { BASE_URL_V2 } from "../../config/config";
/* eslint-disable camelcase */

class accessMgmtService {
  getAccountTypes = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("user-management") + "core-account-types/getAccountTypes",
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  getAppActionData = (accountTypeId, appName) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("user-management") +
            "apps/getAppActions?appName=" +
            appName +
            "&accountTypeId=" +
            accountTypeId,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  updateEndpointPermission = (endpointIds, accountTypeId, isAllowed) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("user-management") +
            "apps/updateEndpointPermission?endpointIds=" +
            endpointIds +
            "&accountTypeId=" +
            accountTypeId +
            "&isAllowed=" +
            isAllowed,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };

  syncAllEndpoints = () => {
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_V2("user-management") + "apps/syncAllEndpoints")
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        })
        .catch((error) => {
          const { response } = error;
          reject(response);
        });
    });
  };
}

const instance = new accessMgmtService();

export default instance;
