import { createSlice } from "@reduxjs/toolkit";
import { InvitedUserService } from "../../services/InvitedUserService";
import { showMessage } from "../Revalu/notificationSlice";

export const getAllInvitedUsers = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  InvitedUserService.getAllInvitedUsers(data)
    .then((res) => {
      dispatch(getInvitedUsersSuccess(res));
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const AddInvitedUser = (data) => async (dispatch) => {
  dispatch(addInviteUserLoading());
  return InvitedUserService.AddInvitedUser(data)
    .then(async (res) => {
      dispatch(addInvitedUserSuccess(res));
      InvitedUserService.getAllInvitedUsers(data).then((res) => {
        dispatch(getInvitedUsersSuccess(res));
      });
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(completeAddInviteUser());
      return true;
    });
};

export const resendOrCancelEmailUser = (data, action) => async (dispatch) => {
  const Service =
    action === "resend"
      ? InvitedUserService.resendEmailUser
      : InvitedUserService.cancelInviteUser;
  dispatch(addInviteUserLoading());
  return Service(data)
    .then(async (res) => {
      dispatch(addInvitedUserSuccess(res));
      const { data, status, description } = res;
      if (Boolean(data) && Number(status) === 200) {
        dispatch(showMessage({ message: description }));
        InvitedUserService.getAllInvitedUsers().then((res) => {
          dispatch(getInvitedUsersSuccess(res));
        });
      }
      return res;
    })
    .catch((errors) => {
      return errors;
    })
    .finally(() => {
      dispatch(completeAddInviteUser());
    });
};

export const resendPassCode = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  InvitedUserService.resendPassCode(data)
    .then((res) => {
      return res;
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const copyCode = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  InvitedUserService.getUserPasscodeAndInviteLink(data)
    .then((res) => {
      navigator.clipboard.writeText(res.data);
      return res;
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const unlockUser = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  InvitedUserService.unlockUser(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

export const linkUserToManufacturer =
  (id, manufacturerId) => async (dispatch) => {
    dispatch(setLoading(true));
    InvitedUserService.linkUserToManufacturer(id, manufacturerId)
      .then((res) => {
        return res;
      })
      .catch((errors) => {})
      .finally(() => {
        dispatch(setLoading(false));
        return true;
      });
  };

export const unlinkUserToManufacturer = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  InvitedUserService.unlinkUserToManufacturer(id)
    .then((res) => {
      return res;
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false));
      return true;
    });
};

const initialState = {
  success: false,
  errors: [],
  product: "",
  data: [],
  orderItems: [],
  loading: false,
  addInvitedUserSuccess: false,
  addInviteUserLoading: false,
  inviteUserData: {},
  selected: null,
};

const inviteUserSlice = createSlice({
  name: "inviteUser/listing",
  initialState,
  reducers: {
    getInvitedUsersSuccess: (state, action) => {
      state.success = true;
      state.data = action.payload ? [...action.payload.data] : [];
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    addInviteUserLoading: (state) => {
      state.addInviteUserLoading = true;
    },
    setSelected: (state, action) => {
      const { id } = action.payload;
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      );
      if (index > -1) {
        state.selected = state.data[index];
      }
    },
    resetSelected: (state) => {
      state.selected = null;
    },
    addInvitedUserSuccess: (state, action) => {
      state.addInvitedUserSuccess = true;
      state.inviteUserData = action.payload.data;
    },
    completeAddInviteUser: (state) => {
      state.addInviteUserLoading = false;
    },
    updateInvitedUsersFunc: (state, action) => {
      const { id } = action.payload;
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      );
      if (index > -1) {
        state.data[index] = action.payload;
      }
    },

    resetState: (state) => {
      state.addInvitedUserSuccess = false;
      state.inviteUserData = {};
      state.addInviteUserLoading = false;
    },
  },
});

export const {
  getInvitedUsersSuccess,
  completeAddInviteUser,
  addInviteUserLoading,
  addInvitedUserSuccess,
  resetState,
  setLoading,
  updateInvitedUsersFunc,
  setSelected,
  resetSelected,
} = inviteUserSlice.actions;

export default inviteUserSlice.reducer;
