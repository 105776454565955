import { yupResolver } from "@hookform/resolvers/yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { submitLogin } from "./../../store/Admin/loginSlice";

import _ from "lodash";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const PREFIX = "Login";

const classes = {
  mainWrapper: `${PREFIX}-mainWrapper`,
  wrapper: `${PREFIX}-wrapper`,
  formWrapper: `${PREFIX}-formWrapper`,
  conditionsWrapper: `${PREFIX}-conditionsWrapper`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.mainWrapper}`]: {
    background: theme.palette.primary.loginBg,
    width: "100%",
  },

  [`& .${classes.wrapper}`]: {
    margin: "auto",
    maxWidth: 450,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    padding: "0px 32px",
    "& h1": {
      margin: 0,
      color: theme.palette.primary.boldFont,
    },
    "& p": {
      fontSize: 12,
      color: theme.palette.primary.fontColor,
      margin: "0px",
      marginBottom: 11,
    },
    "& button": {
      width: "100%",
      padding: 8,
      marginBottom: 7,
    },
    "& .login": {
      background: "#c23939",
      padding: 8,
      marginBottom: 7,
      color: "white",
      borderRadius: "5px !important",
      textDecoration: "none",
      textAlign: "center",
    },
  },

  [`& .${classes.formWrapper}`]: {
    marginBottom: 28,
    "& input": {
      border: "1px solid #aeaeae",
      borderRadius: 13,
      padding: "7px 18px",
      background: theme.palette.primary.textFieldBg,
    },
    "& fieldset": {
      borderRadius: 13,
    },
  },

  [`& .${classes.conditionsWrapper}`]: {
    "& .terms": {
      "& .main": {
        borderBottom: "1px solid #e7e7e7",
        paddingBottom: 11,
        fontWeight: 600,
        color: theme.palette.primary.boldFont,
      },
      "& p": {
        display: "flex",
        fontSize: 12,
        alignItems: "center",
        color: theme.palette.primary.fontColor,
        "& svg": {
          fontSize: 14,
          marginRight: 7,
          color: theme.palette.primary.disable,
        },
        "& a": {
          color: theme.palette.primary.links,
          marginLeft: 4,
        },
        "& span": {
          color: theme.palette.primary.confirm,
          marginLeft: 11,
        },
      },
    },
  },
}));

const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup.string().required("Please enter your password."),
});

const defaultValues = {
  email: "",
  password: "",
};

const Login = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = useSelector(({ user }) => user.userLogin);

  const { control, formState, handleSubmit, setError } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    login.errors.forEach((error) => {
      setError(error.type, {
        type: "manual",
        message: error.message,
      });
    });
  }, [login.errors, setError]);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const onSubmit = (model) => {
    dispatch(submitLogin(model, navigate));
  };

  return (
    <Root className={classes.mainWrapper}>
      <div className={classes.wrapper}>
        <h1>Login</h1>
        <p>Please login and lets get started</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ pt: 1 }}>
            <Controller
              name="email"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  label={"Email"}
                  id="outlined-email"
                  placeholder="test@revalu.io"
                  required
                  sx={{ width: 350 }}
                />
              )}
            />
          </Box>
          <Box sx={{ pt: 3, pb: 2 }}>
            <Controller
              name="password"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  placeholder="Password"
                  label={"Password"}
                  type={passwordVisible ? "text" : "password"}
                  autoComplete=""
                  required
                  id="password-input"
                  sx={{ width: 350 }}
                />
              )}
            />
          </Box>
          <div className={classes.conditionsWrapper}>
            <div className="terms">
              <p className="main">
                <CheckCircleIcon /> I agree to all terms and conditions
              </p>
            </div>
          </div>
          <Button
            id="login-submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            type="submit"
            className={classes.submitButton}
            variant="contained"
            color="primary">
            Login
          </Button>
        </form>
      </div>
    </Root>
  );
};

export default Login;
